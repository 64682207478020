/* Font styles. */
/* line 2, ../sass/style.sass */
body {
  margin: 0;
  /* Setting up font size, leading and font family
   * http://www.markboulton.co.uk/journal/comments/five-simple-steps-to-better-typography */
  font: 13px/1.5em "Helvetica Neue", helvetica, Arial, sans-serif;
  letter-spacing: 0.03em;
}

/* Links */
/* line 11, ../sass/style.sass */
a:link, a:visited {
  color: blue;
  text-decoration: none;
}

/* line 16, ../sass/style.sass */
a:hover, a:active {
  color: red;
  text-decoration: underline;
}

/* Titles. */
/* line 21, ../sass/style.sass */
#site-name {
  font-size: 2.2em;
  line-height: 1.3em;
  font-weight: 300;
  padding: 0 0 0.5em;
  margin: 0;
}

/* line 28, ../sass/style.sass */
h1, h2, h3, h4, h5, h6 {
  line-height: 1.3em;
}

/* line 31, ../sass/style.sass */
h1 {
  font-size: 2.2em;
  font-weight: 300;
  padding: 0 0 0.5em;
  margin: 0;
}

/* line 37, ../sass/style.sass */
h2 {
  font-size: 1.8em;
  font-weight: 300;
  margin-bottom: 0.75em;
}

/* line 42, ../sass/style.sass */
h3 {
  font-size: 1.4em;
  margin-bottom: 1em;
}

/* line 46, ../sass/style.sass */
h4 {
  font-size: 1.2em;
  margin-bottom: 0.8em;
}

/* line 50, ../sass/style.sass */
h5 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 0;
}

/* line 55, ../sass/style.sass */
h6 {
  font-size: 1em;
  font-weight: bold;
}

/* Paragraph styles. */
/* line 60, ../sass/style.sass */
p {
  margin: 0 0 1em 0;
}

/* List styles. */
/* line 64, ../sass/style.sass */
ul, ol {
  margin-left: 0;
  padding-left: 0;
}

/* Table styles. */
/* line 69, ../sass/style.sass */
table {
  width: 99%;
}

/* line 74, ../sass/style.sass */
tr.even td,
tr.odd td {
  padding: 10px 5px;
}

/* line 77, ../sass/style.sass */
tr.odd {
  background-color: white;
}

/* Code. */
/* line 81, ../sass/style.sass */
pre, code, tt {
  font: 1em "andale mono", "lucida console", monospace;
  line-height: 1.5;
}

/* line 85, ../sass/style.sass */
pre {
  background-color: #efefef;
  display: block;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #aaaaaa;
}

/* List styles. */
/* line 93, ../sass/style.sass */
ul {
  list-style-type: disc;
}
/* line 95, ../sass/style.sass */
ul ul {
  list-style-type: circle;
}
/* line 97, ../sass/style.sass */
ul ul ul {
  list-style-type: square;
}
/* line 99, ../sass/style.sass */
ul ul ul ul {
  list-style-type: circle;
}

/* line 102, ../sass/style.sass */
ol {
  list-style-type: decimal;
}
/* line 104, ../sass/style.sass */
ol ol {
  list-style-type: lower-alpha;
}
/* line 106, ../sass/style.sass */
ol ol ol {
  list-style-type: decimal;
}

/* Abbreviation. */
/* line 110, ../sass/style.sass */
abbr {
  border-bottom: 1px dotted #666666;
  cursor: help;
  white-space: nowrap;
}

/* UI improvments. */
/* line 116, ../sass/style.sass */
#edit-title {
  font-size: 24px;
  width: 99%;
}

/* line 120, ../sass/style.sass */
#system-themes-form img {
  width: 100px;
}

/* line 123, ../sass/style.sass */
.form-item .description {
  font-style: italic;
  line-height: 1.2em;
  font-size: 0.8em;
  margin-top: 5px;
  color: #777777;
}

/* line 130, ../sass/style.sass */
#edit-delete {
  color: #cc0000;
}

/* Console, style from slate theme. */
/* line 134, ../sass/style.sass */
div.messages {
  padding: 9px;
  margin: 1em 0;
  color: #003366;
  background: #bbddff;
  border: 1px solid #aaccee;
}

/* line 141, ../sass/style.sass */
div.warning {
  color: #884400;
  background: #ffee66;
  border-color: #eedd55;
}

/* line 146, ../sass/style.sass */
div.error {
  color: white;
  background: #ee6633;
  border-color: #dd5522;
}

/* line 151, ../sass/style.sass */
div.status {
  color: #336600;
  background: #ccff88;
  border-color: #bbee77;
}

/* Region Colors. delete when starting a new theme. */
/* line 157, ../sass/style.sass */
#header, #footer,
#sidebar-first,
#sidebar-second,
#navigation {
  background: rgba(170, 170, 170, 0.2);
}

/* line 2, /Users/catherinewinters/Sites/basic/sites/all/themes/basic_clean/basic/sass/components/neat/grid/_grid.scss */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 11, /Users/catherinewinters/Sites/basic/sites/all/themes/basic_clean/basic/sass/components/neat/grid/_visual-grid.scss */
body:before {
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 68em;
  opacity: 0.4;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: -1;
}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 768px) {
}

/* line 2, /Users/catherinewinters/Sites/basic/sites/all/themes/basic_clean/basic/sass/components/neat/grid/_grid.scss */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* line 11, /Users/catherinewinters/Sites/basic/sites/all/themes/basic_clean/basic/sass/components/neat/grid/_visual-grid.scss */
body:before {
  content: '';
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 68em;
  opacity: 0.4;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: -1;
}
@media screen and (max-width: 480px) {
}
@media screen and (max-width: 768px) {
}
